<template>
  <div>
    <b-overlay :show="loading" rounded="sm" no-fade>
      <b-card>
        <b-row>
          <b-col xl="12" md="12" sm="12" cols="12">
            <h1 class="text-primary my-1">Import Excel Inventory</h1>
            <b-row>
              <b-col cols="6" xl="6" md="12" sm="12">
                <b-form-group class="mb-40">
                  <div>
                    <b-form-file accept=".xls, .xlsx" placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..." @change="importExcelFile" ref="file-input" />
                  </div>
                </b-form-group>
              </b-col>
              <b-col cols="6" xl="6" md="12" sm="12">
                <b-form-group class="mb-40">
                  <b-input-group>
                    <b-form-input id="filter-input" v-model="filter" type="search" placeholder="Type to Search"></b-form-input>
                    <b-input-group-append>
                      <b-button @click="filter = ''" variant="primary">Clear</b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>

            <b-table
              :items="listInventorys"
              :small="true"
              responsive
              selectable
              select-mode="single"
              style="padding-bottom:30px"
              class="position-relative "
              head-variant="dark"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
              :fields="inHeaders"
              striped
              hover
              :current-page="currentPage"
              :per-page="perPage"
              @row-selected="onRowSelected"
            >
              <!-- <template v-slot:cell()="{ value, item, field: { key } }">
                <template v-if="edit != item.id">{{ value }}</template>
                <b-form-input v-else v-model="item[key]" />
              </template> -->

              <template #cell(stockNumber)="data">
                <!-- <b-link :to="{ name: 'inventory-edit', params: { id: data.item.id } }" class="font-weight-bold"> #{{ data.value }} </b-link>-->
                <strong class="text-primary">#{{ data.item.stockNumber }}</strong>
              </template>
              <template #cell(titleValue)="data">
                <span class="font-weight-bold"> {{ data.item.titleValue ? data.item.titleValue : 'N/A' }} </span>
              </template>

              <!-- Column: Listing Price -->
              <template #cell(listingPrice)="data">
                <span class="font-weight-bold"> {{ data.item.listingPrice ? formatPrice(data.item.listingPrice, 2) : formatPrice(0) }} </span>
              </template>

              <!-- Column: Status -->
              <template #cell(status)="data">
                <b-badge v-if="data.item.excelImport == true" pill variant="secondary" class="badge-glow">Closed - Excel</b-badge>
              </template>

              <!-- Column: Publish -->
              <template #cell(publish)="data">
                <b-badge v-if="data.item.publish == 'yes'" active variant="light-success" class="badge-glow ">Published</b-badge>
                <b-badge v-if="data.item.publish == 'no'" variant="light-danger" class="badge-glow ">Unpublished </b-badge>
              </template>

              <template #cell(aspiration)="data"> {{ data.item.aspiration ? data.item.aspiration : 'N/A' }} </template>
              <template #cell(baseMsrp)="data"> {{ data.item.baseMsrp ? data.item.baseMsrp : 'N/A' }} </template>
              <template #cell(boreStroke)="data">{{ data.item.boreStroke ? data.item.boreStroke : 'N/A' }} </template>
              <template #cell(chassisCode)="data">{{ data.item.chassisCode ? data.item.chassisCode : 'N/A' }} </template>
              <template #cell(chassisMaterials)="data"> {{ data.item.chassisMaterials ? data.item.chassisMaterials : 'N/A' }} </template>
              <template #cell(chassisType)="data"> {{ data.item.chassisType ? data.item.chassisType : 'N/A' }} </template>
              <template #cell(collection)="data">
                <b-badge v-if="data.item.collection == true" active variant="light-success" class="badge-glow ">Collection</b-badge>
                <b-badge v-if="data.item.collection == false" variant="light-danger" class="badge-glow ">Non Collection </b-badge>
              </template>
              <template #cell(compressionRatio)="data"> {{ data.item.compressionRatio ? data.item.compressionRatio : 'N/A' }} </template>
              <template #cell(condition)="data">
                <b-badge v-if="data.item.condition == 'NEW' || data.item.condition == 'New'" active variant="light-info" class="badge-glow ">New</b-badge>
                <b-badge v-if="data.item.condition == 'USED' || data.item.condition == 'Used'" variant="light-success" class="badge-glow ">Used </b-badge>
              </template>

              <template #cell(consigment)="data">
                <b-badge v-if="data.item.consigment == true" active variant="light-success" class="badge-glow ">Consignment</b-badge>
                <b-badge v-if="data.item.consigment == false" variant="light-danger" class="badge-glow ">Non Consignment </b-badge>
              </template>

              <template #cell(curbWeight)="data"> {{ data.item.curbWeight ? data.item.curbWeight : 'N/A' }} </template>
              <template #cell(delivered)="data">
                <b-badge v-if="data.item.delivered == 'yes'" active variant="light-success" class="badge-glow ">Delivered</b-badge>
                <b-badge v-if="data.item.delivered == 'no'" variant="light-danger" class="badge-glow ">Not Delivered </b-badge>
              </template>
              <template #cell(description)="data">{{ data.item.description ? data.item.description : 'N/A' }} </template>
              <template #cell(driveTrainValue)="data">{{ data.item.driveTrainValue ? data.item.driveTrainValue : 'N/A' }} </template>
              <template #cell(engineAlignment)="data"> {{ data.item.engineAlignment ? data.item.engineAlignment : 'N/A' }} </template>
              <template #cell(engineCode)="data"> {{ data.item.engineCode ? data.item.engineCode : 'N/A' }} </template>
              <template #cell(engineDisplacement)="data">{{ data.item.engineDisplacement ? data.item.engineDisplacement : 'N/A' }} </template>
              <template #cell(enginePosition)="data"> {{ data.item.enginePosition ? data.item.enginePosition : 'N/A' }} </template>
              <template #cell(extColourValue)="data">{{ data.item.extColourValue ? data.item.extColourValue : 'N/A' }} </template>
              <template #cell(frontAxleWidth)="data"> {{ data.item.frontAxleWidth ? data.item.frontAxleWidth : 'N/A' }} </template>
              <template #cell(frontBrakes)="data"> {{ data.item.frontBrakes ? data.item.frontBrakes : 'N/A' }} </template>
              <template #cell(frontSuspension)="data"> {{ data.item.frontSuspension ? data.item.frontSuspension : 'N/A' }} </template>
              <template #cell(frontTyres)="data"> {{ data.item.frontTyres ? data.item.frontTyres : 'N/A' }} </template>
              <template #cell(fuelSystem)="data">{{ data.item.fuelSystem ? data.item.fuelSystem : 'N/A' }}</template>
              <template #cell(fuelTankCapacity)="data"> {{ data.item.fuelTankCapacity ? data.item.fuelTankCapacity : 'N/A' }} </template>

              <template #cell(height)="data"> {{ data.item.height ? data.item.height : 'N/A' }} </template>
              <template #cell(homepage)="data">
                <b-badge v-if="data.item.homepage == true" active variant="light-success" class="badge-glow ">Homepage Listing</b-badge>
                <b-badge v-if="data.item.homepage == false" variant="light-danger" class="badge-glow ">Not on Homepage </b-badge>
              </template>
              <template #cell(intColourValue)="data">{{ data.item.intColourValue ? data.item.intColourValue : 'N/A' }} </template>
              <template #cell(interiorTrim)="data">{{ data.item.interiorTrim ? data.item.interiorTrim : 'N/A' }} </template>
              <template #cell(length)="data"> {{ data.item.length ? data.item.length : 'N/A' }} </template>
              <template #cell(location)="data">{{ data.item.location ? data.item.location : 'N/A' }} </template>
              <template #cell(makeValue)="data"> {{ data.item.makeValue ? data.item.makeValue : 'N/A' }} </template>
              <template #cell(maximumHorsepower)="data">{{ data.item.maximumHorsepower ? data.item.maximumHorsepower : 'N/A' }} </template>
              <template #cell(maximumTorque)="data"> {{ data.item.maximumTorque ? data.item.maximumTorque : 'N/A' }} </template>
              <template #cell(mileageUnit)="data"> {{ data.item.mileageUnit ? data.item.mileageUnit : 'N/A' }} </template>
              <template #cell(mileageValue)="data"> {{ data.item.mileageValue ? data.item.mileageValue : 'N/A' }} </template>
              <template #cell(modelValue)="data"> {{ data.item.modelValue ? data.item.modelValue : 'N/A' }} </template>
              <template #cell(modelVariation)="data">{{ data.item.modelVariation ? data.item.modelVariation : 'N/A' }}</template>
              <template #cell(modelYearValue)="data"> {{ data.item.modelYearValue ? data.item.modelYearValue : 'N/A' }} </template>
              <template #cell(msrpOptionsValue)="data"> {{ data.item.msrpOptionsValue ? data.item.msrpOptionsValue : 'N/A' }} </template>
              <template #cell(numberOfCylinders)="data"> {{ data.item.numberOfCylinders ? data.item.numberOfCylinders : 'N/A' }} </template>

              <template #cell(numberOfDoors)="data"> {{ data.item.numberOfDoors ? data.item.numberOfDoors : 'N/A' }} </template>
              <template #cell(numberOfValves)="data"> {{ data.item.numberOfValves ? data.item.numberOfValves : 'N/A' }} </template>
              <template #cell(origenalMarketValue)="data"> {{ data.item.origenalMarketValue ? data.item.origenalMarketValue : 'N/A' }} </template>
              <template #cell(rearAxleWidth)="data">{{ data.item.rearAxleWidth ? data.item.rearAxleWidth : 'N/A' }}</template>
              <template #cell(rearBrakes)="data"> {{ data.item.rearBrakes ? data.item.rearBrakes : 'N/A' }} </template>
              <template #cell(rearSuspension)="data"> {{ data.item.rearSuspension ? data.item.rearSuspension : 'N/A' }} </template>
              <template #cell(rearTyres)="data"> {{ data.item.rearTyres ? data.item.rearTyres : 'N/A' }} </template>

              <template #cell(received)="data">
                <b-badge v-if="data.item.received == 'yes'" active variant="light-success" class="badge-glow ">Received</b-badge>
                <b-badge v-if="data.item.received == 'no'" variant="light-danger" class="badge-glow ">Not Received </b-badge>
              </template>

              <template #cell(seatingCapacity)="data"> {{ data.item.seatingCapacity ? data.item.seatingCapacity : 'N/A' }} </template>
              <template #cell(transmissionValue)="data"> {{ data.item.transmissionValue ? data.item.transmissionValue : 'N/A' }} </template>
              <template #cell(user)="data"> {{ data.item.user ? data.item.user : 'N/A' }} </template>
              <template #cell(vinNo)="data">{{ data.item.vinNo ? data.item.vinNo : 'N/A' }}</template>
              <template #cell(wheelbase)="data"> {{ data.item.wheelbase ? data.item.wheelbase : 'N/A' }} </template>
              <template #cell(width)="data"> {{ data.item.width ? data.item.width : 'N/A' }} </template>

              <template #cell(fuelValue)="data"> {{ data.item.fuelValue ? data.item.fuelValue : 'N/A' }} </template>

              <template #cell(bodyValue)="data"> {{ data.item.bodyValue ? data.item.bodyValue : 'N/A' }} </template>

              <template #cell(msrpCurrencyValue)="data">{{ data.item.msrpCurrencyValue ? data.item.msrpCurrencyValue : 'N/A' }}</template>
              <template #cell(userId)="data"> {{ data.item.userId ? data.item.userId : 'N/A' }} </template>
              <template #cell(appraisedPrice)="data"> {{ data.item.appraisedPrice ? formatPrice(data.item.appraisedPrice, 2) : formatPrice(0) }} </template>
              <template #cell(sellingPrice)="data">{{ data.item.sellingPrice ? formatPrice(data.item.sellingPrice, 2) : formatPrice(0) }} </template>
              <template #cell(accessories)="data"> {{ data.item.accessories ? data.item.accessories : 'N/A' }} </template>
              <template #cell(totalMsrpOptions)="data">{{ data.item.totalMsrpOptions ? formatPrice(data.item.totalMsrpOptions, 2) : formatPrice(0) }} </template>
              <template #cell(msrpTotal)="data"> {{ data.item.msrpTotal ? formatPrice(data.item.msrpTotal, 2) : formatPrice(0) }} </template>

              <!-- Column: Actions -->
              <template #cell(actions)="data" v-if="$Can('inventory_show') || $Can('inventory_edit')">
                <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
                  <template #button-content>
                    <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                  </template>

                  <!-- <b-dropdown-item @click="showQuotes(data.item)" v-show="data.item.archive == 'no'">
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">Show Quotes</span>
              </b-dropdown-item>
              <b-dropdown-item @click="showOrders(data.item)" v-show="data.item.archive == 'no'">
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">Show Related Orders</span>
              </b-dropdown-item> -->

                  <b-dropdown-item
                    :to="{
                      name: 'inventory-view',
                      params: { id: data.item.id },
                    }"
                    v-if="$Can('inventory_show')"
                  >
                    <feather-icon icon="FileTextIcon" />
                    <span class="align-middle ml-50">Details </span>
                  </b-dropdown-item>
                  <!-- v-if="data.item.status == 'A'" -->
                  <!-- <b-dropdown-item :to="{ name: 'inventory-edit', params: { id: data.item.id } }" v-if="$Can('inventory_edit') && data.item.status == 'A'">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Edit </span>
              </b-dropdown-item>
              <b-dropdown-item @click="sendToArchive(data.item.id, data)" v-show="(data.item.status == 'PE' || data.item.status == 'A') && $Can('inventory_send_archive')" v-if="data.item.archive == 'no'">
                <feather-icon icon="SendIcon" />
                <span class="align-middle ml-50">Send To Archive </span>
              </b-dropdown-item> -->
                </b-dropdown>
              </template>
            </b-table>
            <div class="mx-2 mb-2">
              <b-row>
                <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
                  <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
                </b-col>

                <!-- Pagination -->
                <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                  <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { BFormFile } from 'bootstrap-vue';
import axiosIns from '@/libs/axios';
import store from '@/store';
import router from '@/router';
import { onUnmounted } from '@vue/composition-api';
import settingsStoreModule from '../settingsStoreModule';
import moment from 'moment';
import _ from 'lodash';
export default {
  components: {
    BFormFile,
  },
  mounted() {
    this.getItems();
  },
  setup() {
    const SETTINGS_APP_STORE_MODULE_NAME = 'settings';
    // Register module
    if (!store.hasModule(SETTINGS_APP_STORE_MODULE_NAME)) store.registerModule(SETTINGS_APP_STORE_MODULE_NAME, settingsStoreModule);
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SETTINGS_APP_STORE_MODULE_NAME)) store.unregisterModule(SETTINGS_APP_STORE_MODULE_NAME);
    });
    return { selected: ['Admin', 'User'] };
  },
  data() {
    return {
      excelJson: {},
      edit: null,
      newitem: '',
      filter: null,
      filterOn: [],
      perPage: 10,
      loading: false,
      items: [],
      currentPage: 1,
      pageOptions: [5, 10, 15, 20],
      totalRows: 0,
      fetchInventorys: [],
      listInventorys: [],
      inHeaders: [
        { key: 'stockNumber', label: 'STOCK NUMBER', class: 'text-center' },
        { key: 'status', label: 'STATUS', class: 'text-center' },
        { key: 'condition', label: 'CONDITION', class: 'text-center' },
        { key: 'titleValue', label: 'TITLE', class: 'text-center' },
        { key: 'extColourValue', label: 'EXTERIOR COLOUR', class: 'text-center' },
        { key: 'intColourValue', label: 'INTERIOR COLOUR', class: 'text-center' },
        { key: 'mileageValue', label: 'MILEAGE', class: 'text-center' },
        { key: 'mileageUnit', label: 'MELEAGE UNIT', class: 'text-center' },
        { key: 'listingPrice', label: 'LISTING PRICE', class: 'text-center' },
      ],
      colWidthFactor: null,
    };
  },
  computed: {
    dataMeta() {
      return {
        from: this.totalRows != 0 ? this.perPage * (this.currentPage - 1) + 1 : 0,
        to: this.perPage * (this.currentPage - 1) + Number(this.perPage) < this.totalRows ? this.perPage * (this.currentPage - 1) + Number(this.perPage) : this.totalRows,
        of: this.totalRows,
      };
    },
  },
  methods: {
    onRowSelected(item) {
      if (this.$Can('inventory_show')) {
        router.push({ name: 'inventory-view', params: { id: item[0].id } });
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    excelDateToJSDate(serial) {
      var utc_days = Math.floor(serial - 25569);
      var utc_value = utc_days * 86400;
      var date_info = new Date(utc_value * 1000);
      return date_info.getDate() + '-' + (date_info.getMonth() + 1) + '-' + date_info.getFullYear();
    },

    getItems() {
      this.loading = true;
      store
        .dispatch('settings/fetchInventorys', [])
        .then((res) => {
          var arrayStatus = [];
          let filterExcelImportTrue = _.filter(res.data.data, function(o) {
            return o.excelImport;
          });

          filterExcelImportTrue.forEach((element) => {
            arrayStatus.push(element.status);

            element.dropList = null;
            element._showDetails = false;
          });
          this.totalRows = filterExcelImportTrue.length;
          this.fetchInventorys = filterExcelImportTrue;
          this.listInventorys = filterExcelImportTrue;
          // const uniqeStatus = Array.from(new Set(arrayStatus));
          // this.tempStatusOptions.forEach((element) => {
          //   uniqeStatus.forEach((item) => {
          //     if (element.value == item) this.statusOptions.push(element);
          //   });
          // });
          res.data.headers = res.data.headers.filter((item) => item !== 'selectedImage');
          this.createTable(res.data.headers, res.data.columns);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    createTable(userheaders, headerColumns) {
      if (userheaders) {
        userheaders.forEach((items) => {
          this.colWidthFactor = this.colWidthFactor + headerColumns.find((x) => x.value == items).percentage;
        });
        userheaders.forEach((items) => {
          var columns = headerColumns.find((x) => x.value == items);
          // var width = 'width: ' + String(columns.percentage * (95 / this.colWidthFactor)) + '%';
          // this.inHeaders.push({
          //   key: items,
          //   label: columns.title,
          //   sortable: true,
          //   class: 'text-center',
          //   thStyle: width,
          // });
        });
        if (this.$Can('inventory_show') || this.$Can('inventory_edit')) {
          this.inHeaders.push({
            key: 'actions',
            class: 'text-center',
            thStyle: 'width: 5%',
          });
        }
        this.loading = false;
      }
    },
    formatPrice(value, val) {
      if (value != null) {
        let val = (value / 1).toFixed(2).replace(',', '.');
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
    },
    importExcelFile(e) {
      this.loading = true;
      var files = e.target.files,
        f = files[0];
      var reader = new FileReader();
      const self = this;
      reader.onload = function(e) {
        var data = new Uint8Array(e.target.result);
        var workbook = XLSX.read(data, { type: 'array' });
        let sheetName = workbook.SheetNames[0];
        /* DO SOMETHING WITH workbook HERE */

        let worksheet = workbook.Sheets[sheetName];

        this.excelJson = XLSX.utils.sheet_to_json(worksheet, { defval: null });

        let controlExcelColumn = 0;
        let matchRegex = /(vinNo|condition|mileageUnit|location|extColour)/g;
        if (this.excelJson.length > 0 && this.excelJson != {}) {
          for (let x in this.excelJson[0]) {
            if (x.match(matchRegex)) {
              controlExcelColumn += 1;
            }
          }

          if (controlExcelColumn === 5) {
            this.excelJson.forEach((date) => {
              if (!date['closingDate'].toString().includes('-')) {
                date['closingDate'] = self.excelDateToJSDate(date['closingDate']);
              }
            });

            axiosIns
              .post('import-excel', this.excelJson)
              .then((res) => {
                this.loading = false;
                self.$refs['file-input'].reset();
                self.getItems();
                self.$swal({
                  title: 'success',
                  text: `${res.data} data successfully added`,
                  icon: 'success',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                });
              })
              .catch((error) => {
                this.loading = false;
                self.$swal({
                  title: 'Error!',
                  text: 'Oops, Something went wrong while loading excel, please try again',
                  icon: 'error',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                });
                console.log(error);
              });
          } else {
            this.loading = false;
            self.$swal({
              title: 'Error!',
              text: 'Oops, This excel table headers not correct. Make sure the headers are correct',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            });
          }
        }
      };
      reader.readAsArrayBuffer(f);
    },
  },
};
</script>
